/**
 * Redirect to a specific locale
 *
 * Use this composable, if you have slugs for alternate locales available for your page.
 * When using simple pages without slugs, use `useRedirectToLocale` composable instead.
 *
 * @param localizedSlugs - The localized slugs for the current page
 * @param defaultSlug - The default slug for the current page, if no localized slug is available.
 */
export default (
  localizedSlugs: { locale: string; slug: string }[],
  defaultSlug = '',
) => {
  const route = useRoute();

  const redirect = async (targetLocale = 'en') => {
    if (localizedSlugs == null || localizedSlugs.length === 0) {
      return;
    }

    const newRouteName = route.name
      .toString()
      .replace(/___(\w+)$/g, `___${targetLocale}`);
    const locSlug = localizedSlugs.find((s) => s.locale === targetLocale);

    if (locSlug == null) {
      // if no localized slug is available, redirect to the default slug
      await navigateTo({
        name: newRouteName,
        params: {
          slug: defaultSlug,
        },
      });
    } else {
      await navigateTo({
        name: newRouteName,
        params: {
          // we need replace in case, the locale is already in the localized slug
          slug: locSlug.slug
            .replace(`/${targetLocale}/`, '/')
            .replace(/^.*?\/([\w-]+)$/g, '$1'),
        },
      });
    }
  };

  /**
   * Redirects to the default locale, depending on, whether the slug for the default locale is available.
   * Otherwise the user will be redirected to the default slug.
   *
   * @returns
   */
  const redirectToDefaultLocale = () => redirect('en');

  return {
    redirect,
    redirectToDefaultLocale,
  };
};
